import React from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    TextField
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {CustomerAnagDetails, Group, GroupType} from "../../../model/Customer";
import {useFormik} from "formik";
import {Trans, useTranslation} from "react-i18next"
import {useCustomers} from "../hook/useCustomer";
import {CustomerStatusesMap} from "../core/DataSources";
import {useGroup} from "../hook/useGroups";
import GroupCompositionList1 from "./GroupCompositionList1";
import {useListComposition} from "../hook/useListComposition";
import {GroupValidation} from "../core/validation/GroupValidation";

function GroupAddModal(props: ModalComponentProps<Group>) {
    const {t} = useTranslation();
    const groupHook = useGroup();
    const [data, setData] = React.useState(props.data);

    const mapCustomer = (res) => {
        let owner;
        if (props.data?.groupId) {
            associationHook.init({
                dataSource: res.data.filter(item => !props.data.members.filter(i => i.customerId === item.customerId).length),
                selected: res.data.filter(item => props.data.members.filter(i => i.customerId === item.customerId).length)
            });

           owner = res.data.filter(i => i.customerId === props.data.ownerId);
        } else {
            associationHook.init({
                dataSource: res.data.filter(item => item.customerId !== props.customerId),
                selected: res.data.filter(item => item.customerId === props.customerId)
            });
        }

        setData({
            ...props.data,
            owner: owner?.length ? owner[0] : null
        });

        return res.data;
    }

    const customersHook = useCustomers(`?status=${CustomerStatusesMap['valid'].join(',')}`, mapCustomer);
    const associationHook = useListComposition<CustomerAnagDetails>();

    React.useEffect(() => {
        mapCustomer({data:  customersHook.customers});
    }, [props.data?.groupId, customersHook.customers])

    const formik = useFormik({
        initialValues: data || new Group(),
        enableReinitialize: true,
        validationSchema: GroupValidation(t),
        onSubmit: (values) => {
            const members =  [...associationHook.selected];
            if (!members.filter(m => m.customerId === props.customerId).length && props.customerId) {
                members.push(new CustomerAnagDetails(props.customerId))
            }

            groupHook.saveGroup({
                ...values,
                ownerId: formik.values.owner.customerId,
                members
            }, () => {
                handleClose();
                return new Group();
            });
        },
    });

    const handleClose = () => {
        formik.resetForm();
        props.onClose();
    }

    const handleGroupTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setValues(prevState => ({
            ...prevState,
            groupType: e.target.value ? GroupType.FATTURAZIONE: GroupType.GRUPPO
        }));
    }

    return  <Dialog
        fullScreen
        open={props.open}
        onClose={() => {return}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit
        }}
    >
        <DialogTitle className="section-header">
            <Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.ADD.TITLE</Trans>
            <div>
                <Button color="error" onClick={handleClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="submit"><Trans>COMMON.SAVE</Trans></Button>
            </div>
        </DialogTitle>
        <DialogContent style={{display:"flex", flexDirection:"column", gap: "1rem"}}>
            <TextField
                fullWidth
                variant="standard"
                id="description"
                name="description"
                label={<Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.NAME</Trans>}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                InputLabelProps={{ shrink: true }}
            />

            <FormGroup className="form-group-horizontal">
                {formik.values &&
                    <FormControl variant="standard" style={{flex: 1}}>
                        <Autocomplete
                            id="ownerId"
                            value={formik.values.owner}
                            onChange={(_, value) => formik.setFieldValue('owner', value)}
                            renderInput={(params) => <TextField {...params} label={
                                <Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.OWNER</Trans>}/>}
                            options={customersHook.customers}
                            getOptionLabel={c => `${c.customerCode} - ${c.businessName}`}
                            isOptionEqualToValue={(option, value) => option.customerId === value.customerId}
                        />
                        <FormHelperText error>{formik.errors.ownerId}</FormHelperText>
                    </FormControl>
                }
                <FormControlLabel
                    label={<Trans>CUSTOMER_DETAILS.FIELDS.GROUPS.IS_BILLING</Trans>}
                    control={
                        <Checkbox
                            id="groupType"
                            name="groupType"
                            checked={+formik.values.groupType === GroupType.FATTURAZIONE}
                            value={+formik.values.groupType === GroupType.FATTURAZIONE}
                            onChange={handleGroupTypeChange}
                            onBlur={formik.handleBlur}
                        />
                    }
                />
            </FormGroup>



            <GroupCompositionList1 compositionHook={associationHook} />
        </DialogContent>
    </Dialog>
}

export default GroupAddModal;